import * as numbers from '@/utils/numbers'
import * as strings from '@/utils/strings'

const ylinkOptions = {
    namespaced: true,
    state: {
        openHost: "http://yms.yealinkops.com/open/api",
        openHostVersion: "v2",
        accessKeyID: "f46106e527344a7db964d6dc68d9d8c9",
        accessKeySecret: "b933bde0ea54427d827777764a70c1c5",
        // 用户认证信息
        authentication: {
            token: localStorage.getItem('yilian_token') ? localStorage.getItem('yilian_token') : '',
        }
    },
    mutations: {
        obtainToken(state, data) {
            state.authentication.token = `bearer ${data}`
            localStorage.setItem('yilian_token', `bearer ${data}`)
        },
        /*eslint no-unused-vars: ["error", { "args": "none" }]*/
        logout(state, data) {
            state.authentication = {}
            localStorage.removeItem('yilian_token')
        }
    },
    actions: {

    },
    getters: {
        baseUrl: (state) => {
            return `${state.openHost}/${state.openHostVersion}`
        },
        headersBasic: (state) => {
            let accessKeyID = state.accessKeyID
            let accessKeySecret = state.accessKeySecret
            const b64str = strings.str2base64(
                `${accessKeyID}:${accessKeySecret}`
            )

            return {
                Authorization: `Basic ${b64str}`,
                nonce: numbers.rndNum(79),
                timestamp: new Date().getTime()
            }
        },
        headers: (state) => {
            return {
                Authorization: state.authentication.token,
                nonce: numbers.rndNum(79),
                timestamp: new Date().getTime()
            }
        },
    }
}

export default ylinkOptions