// 说明
// 需要在项目中install js-base64
/**
 * Description:[将字符串封装为base64编码]
 *
 * @param n  指定n位数
 *
 */
export function str2base64(str) {
    let Base64 = require('js-base64')
    return Base64.encode(str)
}

export function base642str(obj) {
    let Base64 = require('js-base64')
    return Base64.decode(obj)
}