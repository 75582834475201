import Vue from 'vue'
import Vuex from 'vuex'
import Menu from '@/config/menu'
import YlinkOptions from './modules/ylink'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        pageTitle: 'Home', // 页面标题
        menu: Menu, // 系统菜单清单
        message: {
            type: null,
            body: null
        },
        // 每页记录条数
        pageSize: localStorage.getItem('pageSize') ?
            localStorage.getItem('pageSize') : 10,
        // 标签页是否纵向排列
        tabpageVertical: localStorage.getItem('tabpageVertical') ?
            Boolean(localStorage.getItem('tabpageVertical')) : false,
        // 是否长期保持用户登录信息
        rememberMe: localStorage.getItem('rememberMe') ?
            Boolean(localStorage.getItem('rememberMe')) : false,
        // 用户认证信息
        authentication: {
            id: localStorage.getItem('id') ? localStorage.getItem('id') : -1,
            username: localStorage.getItem('username') ?
                localStorage.getItem('username') : '',
            token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
            user_info: localStorage.getItem('user_info') ?
                localStorage.getItem('username') : {}
        }
    },
    mutations: {
        setPageTitle(state, data) {
            state.pageTitle = data
        },
        showMessage(state, type, body) {
            state.message = {
                type,
                body
            }
        },
        setPageSize(state, data) {
            state.pageSize = data
            localStorage.setItem('pageSize', data)
        },
        setTabpageVertical(state, data) {
            if (data) {
                state.tabpageVertical = data
                localStorage.setItem('tabpageVertical', data)
            } else {
                state.tabpageVertical = false
                localStorage.removeItem('tabpageVertical')
            }
        },
        setRememberMe(state, data) {
            state.rememberMe = data
            localStorage.setItem('rememberMe', data)
        },

        setAuth(state, data) {
            state.authentication = data
            localStorage.setItem('id', data.id)
            localStorage.setItem('username', data.username)
            localStorage.setItem('token', data.token)
        },
        /*eslint no-unused-vars: ["error", { "args": "none" }]*/
        logout(state, data) {
            state.authentication = {}
            localStorage.removeItem('id')
            localStorage.removeItem('username')
            localStorage.removeItem('token')
        }
    },
    actions: {
        checkPageTitle({
            commit,
            state
        }, path) {
            for (let k in state.menu) {
                if (state.menu[k].href === path) {
                    commit('setPageTitle', state.menu[k].title)
                    break
                }
                if (state.menu[k].items != undefined) {
                    for (let j in state.menu[k].items) {
                        if (state.menu[k].items[j].href === path) {
                            commit('setPageTitle', state.menu[k].items[j].title)
                            break
                        }
                    }
                }
            }
        },
        changeAuth({
            commit
        }, value) {
            commit('setAuth', value)
        }
    },
    getters: {
        pageSize: (state) => {
            return parseInt(state.pageSize, 10)
        },
        tabpageVertical: (state) => {
            return state.tabpageVertical
        },

        rememberMe: (state) => {
            return state.rememberMe
        },

        userInfo: (state) => {
            return state.authentication.user_info
        },
        authentication: (state) => {
            return state.authentication
        },
        isAuth: (state) => {
            return state.authentication.id == -1 ? false : true
        }
    },
    modules: {
        YlinkOptions,
    }
})