import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};
const routes = [{
        path: "/",
        redirect: "/dashboard",
    },
    {
        path: "/about",
        name: "About",
        component: () =>
            import ("@/views/About.vue"),
    },

    {
        path: "/login",
        name: "Login",
        component: () =>
            import ("../views/auth/Login.vue"),
    },

    {
        path: "/todo",
        name: "Todo",
        component: () =>
            import ("../views/poc/todo/Index.vue"),
    },
    {
        path: "/github",
        name: "Github",
        component: () =>
            import ("../views/poc/github/Index.vue"),
    },
    {
        path: "/animation",
        name: "Animation",
        component: () =>
            import ("../views/poc/test/Index.vue"),
    },

    {
        path: "/",
        name: "Home",
        component: () =>
            import ("@/layout/auth/Index.vue"),
        children: [{
                path: "/dashboard",
                name: "dashboard",
                component: () =>
                    import ("../views/Dashboard.vue"),
            },
            {
                path: "/card",
                name: "Card",
                component: () =>
                    import ("../views/widgets/Card.vue"),
            },
            {
                path: "/project",
                name: "Project",
                component: () =>
                    import ("@/views/dos/Project.vue"),
            },
            {
                path: "/domain",
                name: "domain",
                component: () =>
                    import ("@/views/domain/Index.vue"),
                children: [{
                        path: "/domain/list",
                        name: "List",
                        component: {
                            render(c) {
                                return c("router-view");
                            },
                        },
                        children: [{
                                path: "/domain/list",
                                name: "ListAdd",
                                component: () =>
                                    import ("@/views/domain/list.vue"),
                            },
                            {
                                path: "/domain/list_add",
                                name: "ListAdd",
                                component: () =>
                                    import ("@/views/domain/list_add.vue"),
                            },
                        ],
                    },

                    {
                        path: "/domain/address_pool",
                        name: "AddressPool",
                        component: () =>
                            import ("@/views/domain/address_pool.vue"),
                    },
                    {
                        path: "/domain/address_pool_add",
                        name: "AddressPoolAdd",
                        component: () =>
                            import ("@/views/domain/address_pool_add.vue"),
                    },
                    {
                        path: "/domain/address_alloc",
                        name: "AddressAlloc",
                        component: () =>
                            import ("@/views/domain/address_alloc.vue"),
                    },
                    {
                        path: "/domain/address_alloc_add",
                        name: "AddressAllocAdd",
                        component: () =>
                            import ("@/views/domain/address_alloc_add.vue"),
                    },
                    {
                        path: "/domain/rules_mapping",
                        name: "RulesMapping",
                        component: () =>
                            import ("@/views/domain/rules_mapping.vue"),
                    },
                    {
                        path: "/domain/rules_mapping_add",
                        name: "RulesMappingAdd",
                        component: () =>
                            import ("@/views/domain/rules_mapping_add.vue"),
                    },
                    {
                        path: "/domain/rules",
                        name: "RULES",
                        component: () =>
                            import ("@/views/domain/rules.vue"),
                    },
                    {
                        path: "/domain/gend",
                        name: "GEND",
                        component: () =>
                            import ("@/views/domain/gend_address.vue"),
                    },
                ],
            },
            {
                path: "/organ",
                name: "organ",
                component: () =>
                    import ("@/views/organ/Index.vue"),
                children: [{
                        path: "/organ/list",
                        name: "List",
                        component: {
                            render(c) {
                                return c("router-view");
                            },
                        },
                        children: [{
                            path: "/organ/list",
                            name: "List",
                            component: () =>
                                import ("@/views/organ/organ.vue"),
                        }, ],
                    },
                    {
                        path: "/organ/allocate_add",
                        name: "AllocateAdd",
                        component: () =>
                            import ("@/views/organ/allocate_add.vue"),
                    },
                    {
                        path: "/organ/allocate",
                        name: "Allocate",
                        component: () =>
                            import ("@/views/organ/allocate.vue"),
                    },
                ],
            },
            //错误页
            {
                path: "*",
                name: "p404",
                component: () =>
                    import ("@/views/404.vue"),
            },
        ],
    },
];

const routesYilianAPI = [];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    routesYilianAPI,
});

router.beforeEach((to, from, next) => {
    router.app.$store.dispatch("checkPageTitle", to.path);
    /* eslint-disable no-undef */
    if (typeof ga !== "undefined") {
        ga("set", "page", to.path);
        ga("send", "pageview");
    }
    next();
});

export default router;