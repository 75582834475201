import Vue from "vue";
import axios from "@/plugins/axios";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "@/plugins/vuetify";

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";

// datetime picker
import DatetimePicker from "vuetify-datetime-picker";
Vue.use(DatetimePicker);

import moment from "moment"; //导入文件
Vue.prototype.$moment = moment; //赋值使用

// json格式化显示
import JsonViewer from 'vue-json-viewer'
Vue.use(JsonViewer)

import utils from "@/utils/index.js";
Vue.use(utils);

Vue.config.productionTip = false;
Vue.config.$router = router;

// Message 组件
import message from "vuetify-message-snackbar";
Vue.use(message);

new Vue({
  store,
  router,
  vuetify,
  axios,
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
  created() {
    this.$store.dispatch("checkPageTitle", this.$route.path);
  },
}).$mount("#app");