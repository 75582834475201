/**
 * Description:[输出指定n位数的随机数的随机整数]
 *
 * @param n  指定n位数
 *
 */
export function rndNum(n) {
    var rnd = "";
    for (var i = 0; i < n; i++) {
        rnd += Math.floor(Math.random() * 10);
    }
    return rnd;
}