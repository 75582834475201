// 转换字符串，undefined,null等转化为""
export function praseStrEmpty(str) {
  return !str || str === "undefined" || str === "null" ? "" : str;
}

// 首字母大写
export function titleCase(str) {
  return str.replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
}

// 横线- 转驼峰
export function camelCase(str) {
  return str.replace(/-[a-z]/g, (str1) => str1.substr(-1).toUpperCase());
}
