export default [
    {
        icon: 'mdi-satellite-variant',
        title: '线索管理',
        href: '',
        items: [
            {
                icon: 'mdi-apps',
                title: '线索列表',
                href: '/clue/list'
            },
            {
                icon: 'mdi-apps',
                title: '线索录入',
                href: '/clue/clue'
            }
        ]
    },
    {
        icon: 'mdi-database-plus',
        title: '案件管理',
        href: '',
        items: [
            {
                icon: 'mdi mdi-clipboard-text',
                title: '案件列表',
                href: '/case/caseList'
            },
            {
                icon: 'mdi mdi-clipboard-text',
                title: '案件新建',
                href: '/case/case'
            }
        ]
    },
    {
        icon: 'mdi-checkbox-multiple-marked',
        title: '补充侦查',
        href: '',
        items: [
            {
                icon: 'mdi-chart-bubble',
                title: '人员轨迹',
                href: '/investigate/people'
            },
            {
                icon: 'mdi-chart-bubble',
                title: '物流信息',
                href: '/investigate/logistics'
            },
            {
                icon: 'mdi-chart-bubble',
                title: '通讯流信息',
                href: '/investigate/communication'
            },
            {
                icon: 'mdi-chart-bubble',
                title: '资金流信息',
                href: '/investigate/capital'
            }
        ]
    },
    {
        icon: 'mdi-magnify-plus-outline',
        title: '分析研判',
        href: '',
        items: [
            {
                icon: 'mdi-chart-bubble',
                title: '分析研判',
                href: '/analysis/analysis'
            }
        ]
    },
    {
        icon: 'mdi-flash-auto',
        title: '侦破打击',
        href: '',
        items: [
            {
                icon: 'mdi-flash-auto',
                title: '侦破打击',
                href: '/detection/detection'
            }
        ]
    },
    {
        icon: 'mdi-book-open-variant',
        title: '技战法',
        href: '',
        items: [
            {
                icon: 'mdi mdi-clipboard-text',
                title: '顺藤摸瓜技战法',
                href: '/technical/clue/clue'
            }
        ]
    },
    {
        icon: 'mdi-chart-bubble',
        title: '数智单元',
        href: '',
        items: [
            {
                icon: 'mdi-chart-bubble',
                title: '数智单元管理',
                href: '/technical/intelligence'
            }
        ]
    },
    {
        icon: 'mdi-account-key',
        title: '系统设置',
        href: '',
        items: [
            {
                icon: 'mdi-chart-bubble',
                title: '技战法管理',
                href: '/system/bom'
            },
            {
                icon: 'mdi-chart-bubble',
                title: '日志管理',
                href: '/system/log'
            },
            {
                icon: 'mdi-chart-bubble',
                title: '关系管理',
                href: '/system/relationship'
            },
            {
                icon: 'mdi-chart-bubble',
                title: '主数据管理',
                href: '/system/dataoptions'
            }
        ]
    }
]
