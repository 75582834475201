import * as formaters from "./formaters";
import * as numbers from './numbers'
import * as strings from './strings'

const Utils = {};

Utils.install = (Vue) => {
  Vue.myGlobalMethod = function () {

  }
  Vue.prototype.$utils = Utils;
  Vue.prototype.$utils.formaters = formaters;
  Vue.prototype.$utils.numbers = numbers;
  Vue.prototype.$utils.strings = strings;
};

export default Utils;